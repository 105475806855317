/*  ==========================================================================
    Shared
    ==========================================================================  */

    html {
        scroll-behavior: smooth;
    }

    body {
        color: $text-color;

        &.header-fixed-space-default {
            padding-top: 50px;
        }
    }

    @media (min-width: 768px) {
        body {

            &.header-fixed-space-default {
                padding-top: 72px;
            }
        }
    }

    @media (min-width: 1024px) {
        body {

            &.header-fixed-space-default {
                padding-top: 88px;
            }
        }
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        font-weight: $headings-font-weight;
        color: $jotun-blue;
        text-align: left;
    }

    h1, .h1 {
        font-size: 3.052em;
        line-height: $headings-line-height;
    }

    h2, .h2 {
        font-size: 2.441em;
        line-height: $headings-line-height;
    }

    h3, .h3 {
        font-size: 1.953em;
        line-height: $headings-line-height;
    }

    h4, .h4 {
        font-size: 1.563em;
        line-height: $headings-line-height;
    }

    h5, .h6 {
        font-size: 1.25em;
        line-height: $headings-line-height;
    }

    h6, .h6 {
        line-height: $headings-line-height;
    }

    small {
        font-size: 0.775em;
    }

    .w-100 {
        width: 100%;
    }

    .visible {
        visibility: visible;
    }

    .invisible {
        visibility: hidden;
    }

    .text-limit {
        max-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .form-check-inline {
        margin-right: 0;
    }

    .form-control.cal-control {
        padding: 6px 15px;
        border-radius: 0;
        border-width: 2px;
        text-align: center;
    }

    .form-control.textarea-control {
        height: 149px;
    }

    .form-group label {
        margin: 0;
        font-size: 14px;
        line-height: 1.42857143;
        color: $text-color;
    }

    .input-group:has(.is-invalid) {
        .input-group-text {
            border-color: $danger;
        }
    }

    .form-date-picker {

        &.is-invalid {

            input {
                border-color: $danger!important;
            }

            svg {
                fill: $danger;
    
                path {
                    fill: $danger;
                }
            }
        }

        input {
            display: block;
            width: 100%;
            height: auto;
            padding: 0.375rem 0.75rem!important;
            font-size: 0.86rem;
            font-weight: 400;
            line-height: 1.428571429;
            color: #212529;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $light!important;
            -webkit-appearance: none;
            appearance: none;
            border-radius: 0.1rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            outline: none!important;
        }

        svg {
            fill: #343A40;

            path {
                fill: #343A40;
            }
        }
    }

    .vehicle-count {
        height: 41px;
        padding: 10px;
        background: $white;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: $jotun-blue;
    }

    .vehicle-title {
        font-size: 18px;
        line-height: 21px;
    }

    .price {
        font-size: 30px;
        line-height: 28px;
        font-weight: 600px;
    }

    .tel {
        font-size: 18px;
        text-decoration: none;
    }

    .or-text {
        font-size: 22px;
        line-height: 40px;
        font-weight: bold;
        color: #999999;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

/*  ==========================================================================
    Background colours
    ==========================================================================  */

    .bg-primary {
        background-color: var(--primaryBg)!important;
    }

    .bg-secondary {
        background-color: var(--secondaryBg)!important;
    }

    .bg-dark-grey {
        background-color: $darkgrey;
    }

    .bg-mid-grey {
        background-color: $midgrey;
    }

    .bg-light-grey {
        background-color: $lightgrey;
    }

    .bg-acc-grey {
        background: $account-grey;
    }

    .bg-grey {
        background-color: $grey;
    }

    .bg-white {
        background-color: $white;
    }

    .bg-blue {
        background-color: $jotun-blue;
    }

    .bg-aff {
        background-color: $aff-blue;
    }

    .bg-red {
        background-color: $aff-red;
    }

/*  ==========================================================================
    Text colours
    ==========================================================================  */

    .text-primary {
        color: var(--primaryBg)!important;
    }

    .text-secondary {
        color: var(--secondaryBg)!important;
    }

    .text-default {
        color: $text-color;
    }

    .text-black {
        color: $trueblack;
    }

    .text-blue {
        color: $jotun-blue;
    }

    .text-aff {
        color: $aff-blue;
    }

    .text-acc {
        color: $account-green;
    }

    .text-red {
        color: $aff-red;
    }

    .text-white {
        color: $white;
    }

    .text-mid-grey {
        color: $midgrey;
    }

    .text-light-grey {
        color: $lightgrey;
    }

    .text-dark-grey {
        color: $darkgrey;
    }

/*  ==========================================================================
    Z index
    ==========================================================================  */

    .z-xs {
        z-index: 1;
    }

    .z-sm {
        z-index: 2;
    }

    .z-md {
        z-index: 3;
    }

    .z-lg {
        z-index: 4;
    }

    .z-xl {
        z-index: 5;
    }

/*  ==========================================================================
    Border colours
    ==========================================================================  */

    .border-primary {
        border-color: var(--primaryBg)!important;
    }

    .border-secondary {
        border-color: var(--secondaryBg)!important;
    }

/*  ==========================================================================
    Shadow
    ==========================================================================  */
    
    .shadow {
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    }

/*  ==========================================================================
    Loading
    ==========================================================================  */

    .loading {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0,0,0, 0.5);
    }

/*  ==========================================================================
    Breadcrumbs
    ==========================================================================  */

    .breadcrumbs {
        width: 100%;
        position: relative;
        background-color: $white;

        .breadcrumbs__inner {
            @extend .container;

            .breadcrumbs__section {
                position: relative;

                .breadcrumbs__crumb {
                    display: inline-block;
                    padding: 9px 9px 9px 18px;
                    font-size: 8px;
                    color: #e5332d;
                    text-transform: uppercase;
                }

                .breadcrumbs__separator {
                    display: inline-block;
                    font-size: 8px;
                    color: #e5332d;
                    cursor: default;
                }
            }
        }
    }

/*  ==========================================================================
    Main Icons
    ==========================================================================  */

    .main-icon {
        width: 53px;
        height: 53px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        &.calculator {
            background-position-x: 0;
        }

        &.thumbs-up {
            background-position-x: -53px;
        }

        &.pound-symbol {
            background-position-x: -106px;
        }

        &.question-mark {
            background-position-x: -159px;
        }

        &.smiley-face {
            background-position-x: -212px;
        }

        &.umbrella {
            background-position-x: -265px;
        }
    }

/*  ==========================================================================
    Share Icons
    ==========================================================================  */

    .social-icon {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        line-height: 40px;
        margin-right: 4px;
        margin-left: 4px;

        &.twitter {
            background-position-x: 0;
        }

        &.facebook {
            background-position-x: -40px;
        }

        &.gplus {
            background-position-x: -80px;
        }

        &.instagram {
            background-position-x: -120px;
        }

        &.mail {
            background-position-x: -160px;
        }
    }

/*  ==========================================================================
    Share Icons
    ==========================================================================  */

    .share-icon {
        width: 58px;
        height: 60px;
        display: inline-block;
        vertical-align: middle;
        line-height: 60px;
        margin-right: 2px;
        margin-left: 2px;
        background-position-x: center;

        &.facebook {
            background-position-y: 0;
        }

        &.twitter {
            background-position-y: -60px;
        }

        &.gplus {
            background-position-y: -120px;
        }

        &.instagram {
            background-position-y: -180px;
        }

        &.linkedin {
            background-position-y: -240px;
        }
    }

/*  ==========================================================================
    How it works
    ==========================================================================  */

    .hiw-icon {
        width: 52px;
        max-width: 52px;
        height: 52px;
        display: inline-block;
        flex: 1 0 auto;
        vertical-align: middle;
        background-position-x: center;

        &.hiw-icon__1 {
            background-position-y: 0;
        }

        &.hiw-icon__2 {
            background-position-y: -52px;
        }

        &.hiw-icon__3 {
            background-position-y: -104px;
        }

        &.hiw-icon__4 {
            background-position-y: -156px;
        }

        &.hiw-icon__5 {
            background-position-y: -208px;
        }

        &.hiw-icon__6 {
            background-position-y: -260px;
        }

        &.hiw-icon__7 {
            background-position-y: -312px;
        }
    }

/*  ==========================================================================
    Badge Icons
    ==========================================================================  */

    .badge-icon {
        display: block;
        margin: 0 5px 0 0;
        float: left;
        width: 60px;
        max-width: 20%;
        opacity: 1;
    }

/*  ==========================================================================
    Header
    ==========================================================================  */

    .header-block {
        width: 100%;
        height: 72px;
        position: relative;
        margin: 0;
        padding: 20px 10px;
        font-size: 22px;
        font-weight: 600;
    }

/*  ==========================================================================
    Box
    ==========================================================================  */

    .box {
        width: 100%;
        position: relative;
        background-color: $lightgrey;
        border-radius: 10px;
        padding: 15px;

        &.box--dark {
            background-color: $darkgrey;
        }

        &.box--white {
            background-color: #fff;
        }

        &.box--block {
            position: relative;
        }

        &.box--rounded {
            border-radius: 10px;
        }

        &.box--contact {
            min-height: 250px;
        }

        .box__inner {
            width: 100%;
            position: relative;
        }
    }

/*  ==========================================================================
    Accordion
    ==========================================================================  */

    .accordion {
        width: 100%;
        position: relative;

        .accordion-item {
            width: 100%;
            position: relative;

            .accordion-item__toggle {
                width: 100%;
                position: relative;
                background: $white;
                border-bottom: 1px solid #ececec;
                padding: 10px 0;
                font-size: 15px;
                font-weight: normal;
                color: #3c3c39;
                cursor: pointer;

                &:after {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    margin: -10px 0 0 0;
                    content: "";
                    z-index: 1;
                    background: transparent;
                    border-radius: 100%;
                }

                &.is-selected {

                    &:after {
                        background-color: $success;
                    }
                }
            }

            .accordion-item__content {
                width: 100%;
                position: relative;
                padding: 0px 15px;
                display: none;
            }

            .accordion-item__option {
                width: 100%;
                position: relative;
                border-bottom: 1px solid #ececec;
                padding: 10px 0;
                font-size: 15px;
                font-weight: normal;
                color: #3c3c39;

                &.is-disabled {
                    cursor: not-allowed;
                }

                img {
                    display: inline-block;
                    margin: 0 10px 0 0;
                }

                strong {
                    display: inline-block;
                    min-width: 80px;
                    text-align: right;
                }
            }

            .accordion-item__selector {
                width: 34px;
                height: 34px;
                position: relative;
                display: block;
                border-radius: 100%;
                margin: 0 5px 0 auto;
                padding: 5px;
                border: 1px solid $lightgrey;
                cursor: pointer;


                &:after {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 6px;
                    left: 6px;
                    content: "";
                    z-index: 1;
                    background: $lightgrey;
                    border-radius: 100%;
                }

                &.is-active {

                    &:after {
                        background-color: $success;
                    }
                }
            }

            &.is-active {
                .accordion-item__content {
                    display: block;
                }
            }
        }
    }

/*  ==========================================================================
    Pagination
    ==========================================================================  */

    .pagination {
        width: auto;
        display: inline-block;
        margin: 0;
        padding: 0;

        span {
            font-size: 16px;
            text-transform: uppercase;
            color: #1b1c1d;
            margin-right: 10px;
            line-height: 30px;
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 600;
        }

        a, button {
            background: none repeat scroll 0 0 #fff;
            border-bottom: 2px solid #edeeed;
            border-radius: 20px;
            color: #2d2d2b;
            cursor: pointer;
            display: inline-block;
            font-weight: 600;
            height: 35px;
            line-height: normal;
            margin: 0 4px;
            padding: 8px 0;
            text-align: center;
            transition: all .4s ease 0s;
            width: 35px;
            text-decoration: none;

            &.is-active {
                background: none repeat scroll 0 0 #0d668a;
                border-bottom: 2px solid #ba4f4b;
                color: #fff;
            }
        }
    }

    @media (min-width: 768px) {
        .pagination {
            padding: 20px 0 0 0;
        }
    }

/*  ==========================================================================
    Popover
    ========================================================================== */

    .popover {
        position: absolute;
        z-index: $zindex-popover;
        display: block;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
        line-break: auto;
        line-height: 1.42857143;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        white-space: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        font-size: 12px;
        opacity: 0.9;
        background-color: rgba(0,0,0,.9);
        filter: alpha(opacity=90);

        .popover-header {
            font-weight: bold;
            font-size: 14px;
            background: none;
            border: 0;
            padding: 10px 10px 0 10px;
        }

        .popover-body {
            padding: 10px;
            font-size: 12px;
            font-weight: normal;
        }

        .popover-header, .popover-body {
            color: $white;
        }

        &.bs-popover-top > .popover-arrow::before, &.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, &.bs-popover-top > .popover-arrow::after, &.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
            border-top-color: rgba(0,0,0,.9);
        }

        &.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, &.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
            border-bottom-color: rgba(0,0,0,.9);
        }

        &.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, &.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
            border-left-color: rgba(0,0,0,.9);
        }

        &.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, &.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
            border-right-color: rgba(0,0,0,.9);
        }
    }