@import "../../../../sass/_variables";
@import "../../../../sass/_mixins";

.header {
    background-color: $white;

    img {
        max-width: 100%;
        max-height: 55px;
        height: auto;
    }
}

@media (min-width: 768px) {
    .header {
    
        img {
            max-width: 230px;
        }
    }
}