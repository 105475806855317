@import "../../../sass/_variables";
@import "../../../sass/_mixins";

.input-wrapper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    input, .input-icon {
        display: inline-flex;
    }

    .input-icon {
        border: 0;
        padding: 10px;

        svg {
            color: $white;
        }
    }

    .input-loading {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 15px;
        margin: -10px 0 0 0;
        z-index: 2;
    }

    .input-list {
        max-height: 350px;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: $zindex-dropdown;
        display: none;
        background: $white;
        border: 1px solid $light;
        overflow-y: auto;

        &.is-active {
            display: block;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: block;
                padding: 5px 10px;
                font-weight: normal;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background-color: var(--primaryBg);
                    color: $white;
                }
            }
        }
    }
}
