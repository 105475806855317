@import "../../../../sass/_variables";
@import "../../../../sass/_mixins";

.footer {
    background-color: $black;

    p {
        font-size: 10px;
        color: $white;
    }

    a {
        color: inherit;
    }  
}