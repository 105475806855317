@import "../../../../sass/_variables";
@import "../../../../sass/_mixins";

.header-block {
    width: 100%;
    position: relative;

    &.light {

        hr {
            background-color: $white;
        }

        h3, h4 {
            color: $white;
        }
    }

    hr {
        width: 150px;
        height: 5px!important;
        position: relative;
        display: inline-block;
        border: 0;
        opacity: 1;
    }
}
