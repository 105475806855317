.circled-check {
    width: 30px !important;
    height: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    background: green;
    border-radius: 100%;

    svg {
        width: 10px !important;
        height: 10px !important;
        fill: white;

        path {
            fill: white;
        }
    }
}
