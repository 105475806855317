/*  ==========================================================================
    https://raw.githubusercontent.com/twbs/bootstrap-sass/master/assets/stylesheets/bootstrap/_variables.scss
    ==========================================================================  */

/*  ==========================================================================
    Grid
    ==========================================================================  */

	// Grid breakpoints
	//
	// Define the minimum dimensions at which your layout will change,
	// adapting to different screen sizes, for use in media queries.
	$grid-breakpoints: (
	  xs: 0,
	  sm: 768px,
	  md: 1024px,
	  lg: 1200px,
	) !default;

	$screen-xs: 480px !default;
	$screen-ml: 667px !default;
	$screen-sm: 768px !default;
	$screen-md: 1024px !default;
	$screen-lg: 1200px !default;

	// Grid containers
	//
	// Define the maximum width of `.container` for different screen sizes.
	$container-max-widths: (
	  sm: 724px,
	  md: 980px,
	  lg: 1140px,
	) !default;

	$grid-columns:             24 !default;
	$grid-gutter-width: 	   30px !default;

/*  ==========================================================================
    Colours
    ==========================================================================  */
	
    $default:   #333333 !default;
	$primary:   #0057C8 !default;
	$secondary: #333333 !default;
	$success:   #00861a !default;
	$danger:    #830303 !default;
	$warning:   #FF9500 !default;
	$info:      #000000 !default;
	$light:     #e6e6e6 !default;
	$dark:      #000000 !default;
	$white:     #ffffff !default;

	$accent:        #e74c3c !default;
	$silver:        #F4F4F4 !default;
	$lightgrey:     #ececec !default;
	$grey:          #ccc !default;
	$midgrey:       #999 !default;
	$darkgrey:      #3f3f3f !default;
	$black:         #26282a !default;
	$trueblack:     #000 !default;
	$confirmation:  #4CAF50 !default;

	$np-yellow:     #ffc400 !default;
	$aff-blue:      #0d668a !default;
	$aff-red:       #e6352e !default;
	$jotun-blue:    #002664 !default;
	$account-grey:  #F4F0F0 !default;
	$account-green: #0c8d91!default;
	
	$gray-base:                  #000 !default;
	$gray-darker:                lighten($gray-base, 13.5%) !default; // #222
	$gray-dark:                  lighten($gray-base, 20%) !default;   // #333
	$gray:                       lighten($gray-base, 33.5%) !default; // #555
	$gray-light:                 lighten($gray-base, 46.7%) !default; // #777
	$gray-lighter:               lighten($gray-base, 93.5%) !default; // #eee

    $border-color: 			     #333 !default;

	$body-bg:                    #fff !default;
	$text-color:                 #333 !default;
	$link-color:                 $accent !default;
	$link-hover-color:           darken($link-color, 15%) !default;
	$link-hover-decoration:      underline !default;
	$button-default:             #002664!default;
	$button-default-hover:       #0784ba!default;
	$button-grey: 			     #3c3c39!default;
	$button-grey-hover:          #0b0b0a!default;
	$button-mid-grey: 		     #9c9c9c!default;
	$button-mid-grey-hover:      #727272!default;
	$button-light-grey: 		 #9c9c9c!default;
	$button-light-grey-hover:    #727272!default;
	$button-brand: 			     #39ac33!default;
	$button-brand-hover:         #20921a!default;
	$button-text-color:          $white;

/*  ==========================================================================
    Typography
    ==========================================================================  */

	$font-family-sans-serif:   'Poppins', sans-serif !default;
	$font-family-serif:        Georgia, Times New Roman, serif !default;

	$font-family-monospace:    Menlo, Monaco, Consolas, "Courier New", monospace !default;
	$font-family-base:         $font-family-sans-serif !default;

	$font-size-base:           0.86rem !default;
	$font-size-large:          ceil(($font-size-base * 1.25)) !default;
	$font-size-small:          ceil(($font-size-base * 0.85)) !default;

	$font-size-h1:             floor(($font-size-base * 2.8)) !default;
	$font-size-h2:             floor(($font-size-base * 2.5)) !default;
	$font-size-h3:             ceil(($font-size-base * 2)) !default;
	$font-size-h4:             ceil(($font-size-base * 1.2)) !default;
	$font-size-h5:             ceil(($font-size-base * 1)) !default;
	$font-size-h6:             ceil(($font-size-base * 0.85)) !default;

	$line-height-base:         1.428571429 !default;
	$line-height-computed:     floor(($font-size-base * $line-height-base)) !default;

	$headings-font-family:     inherit !default;
	$headings-font-weight:     500 !default;
	$headings-line-height:     1.1 !default;
	$headings-color:           inherit !default;

/*  ==========================================================================
    Padding
    ==========================================================================  */
	
	$padding-base-vertical:    6px !default;
	$padding-base-horizontal:  12px !default;
	$padding-large-vertical:   10px !default;
	$padding-large-horizontal: 16px !default;
	$padding-small-vertical:   5px !default;
	$padding-small-horizontal: 10px !default;
	$padding-xs-vertical:      1px !default;
	$padding-xs-horizontal:    5px !default;

/*  ==========================================================================
    Z-index
    ==========================================================================  */

	$zindex-navbar:           1000 !default;
	$zindex-dropdown:         1000 !default;
	$zindex-popover:          1060 !default;
	$zindex-tooltip:          1070 !default;
	$zindex-navbar-fixed:     1030 !default;
	$zindex-modal-background: 1040 !default;
	$zindex-modal:            1050 !default;

/*  ==========================================================================
    Images
	==========================================================================  */	
	
	$img-ratio:				  66.66% !default; // 3:2 Aspect Ratio	

/*  ==========================================================================
    Border Radius
    ==========================================================================  */

    $border-radius:    .1rem;
    $border-radius-sm: .3rem;
    $border-radius-lg: .5rem;