@import "../../../../sass/_variables";
@import "../../../../sass/_mixins";

.to-top {
    width: 32px;
    height: 32px;
    position: fixed;
    right: 30px;
    bottom: -100%;
    z-index: 9999;
    border-radius: 3px;
    padding: 5px!important;
    transition: all 1s ease-in-out;
    text-align: center;

    &.is-active {
        bottom: 30px;
    }

    svg {
        width: 20px;
        height: 20px;
        margin: 0 !important;
        fill: $white;

        path {
            fill: $white;
        }
    }
}