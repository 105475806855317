.slide-up {
    animation: slide-up 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
    animation-delay: 0.3s;
}

.slide-out {
    animation: slide-out 0.3s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slide-out {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100px);
    }
}