@import "../../../sass/_variables";
@import "../../../sass/_mixins";

.indicator {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    &:before {
        max-width: 100%;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 1;
        content: "";
        border: 1px solid $grey;
    }

    .progress {
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        content: "";
        border: 1px solid $success;
    }

    .circle {
        width: 20px;
        height: 20px;
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background-color: $grey;
        font-weight: bold;
        font-size: 10px;
        line-height: 10px;
        color: $white;
        border-radius: 100%;
        border: 0;
        outline: 0;
        transition: all .5s ease-in-out;

        &.is-current {
            transform: scale(1.2);
            background-color: $success;
            box-shadow: 0 0 0 0 rgba($success, .5);
            animation: pulseSuccess 2s infinite;
        }

        &.is-active {
            background-color: $success;
        }

        &.is-clickable {
            cursor: pointer;

            &:hover {
                background-color: $aff-blue;
                box-shadow: 0 0 0 0 rgba($aff-blue, .5);
                animation: pulsePrimary 2s infinite;
            }
        }

        &:first-child:before {
            display: none !important;
        }
    }
}

@media screen and (min-width: 768px) {
    .indicator {
        .circle {
            width: 40px;
            height: 40px;
            font-size: 16px;
            line-height: 16px;
        }
    }
}

@keyframes pulsePrimary {
    50% {
        box-shadow: 0 0 0 10px rgba($aff-blue, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba($aff-blue, 0);
    }
}

@keyframes pulseSuccess {
    50% {
        box-shadow: 0 0 0 10px rgba($success, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba($success, 0);
    }
}
