/* ==========================================================================
   Buttons
   ========================================================================== */

    button,
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button;
        cursor: pointer;	
    }

    .btn {
        border: 0;
        text-decoration: none;
        transition: background-color .5s ease-in-out;

        &:active,
        &:focus {
            outline: none;
            transition: background-color .5s ease-in-out;
        }

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        /* === Sizing === */
            /* Fluid */   
            &.btn-fluid, &.btn-fluid-xs {
                min-width: auto;
            }

            /* Block */
            &.btn-block, &.btn-block-xs {
                width: 100%;
                display: block;
            }
        
        /* === Custom === */ 
            /* Theme */
            &.btn-primary {
                &,
                &:link,
                &:visited {
                    background: var(--primaryBg);
                    border-color: var(--primaryBg);
                    color: var(--primaryText);
                }

                &:hover,
                &:focus {
                    background: var(--primaryBgHover);
                    border-color: var(--primaryBgHover);
                }
            }

            &.btn-outline-primary {
                &,
                &:link,
                &:visited {
                    background: transparent;
                    border-color: var(--primaryBg);
                    color: var(--primaryBg);
                }

                &:hover,
                &:focus {
                    background: var(--primaryBgHover);
                    border-color: var(--primaryBgHover);
                }
            }

            &.btn-secondary {
                &,
                &:link,
                &:visited {
                    background: var(--secondaryBg);
                    border-color: var(--secondaryBg);
                    color: var(--secondaryText);
                }

                &:hover,
                &:focus {
                    background: var(--secondaryBgHover);
                    border-color: var(--secondaryBgHover);
                }
            }

            &.btn-outline-secondary {
                &,
                &:link,
                &:visited {
                    background: transparent;
                    border-color: var(--secondaryBg);
                    color: var(--secondaryBg);
                }

                &:hover,
                &:focus {
                    background: var(--secondaryBgHover);
                    border-color: var(--secondaryBgHover);
                }
            }
    }

    @media only screen and (min-width: 768px) {
        .btn {
            &.btn-fluid-xs {
                width: auto;
                min-width: 260px;
            }

            &.btn-block-xs {
                width: auto;
                display: inline-block;
            }
        }
    }