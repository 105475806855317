/*  ==========================================================================
    Variables
    ==========================================================================  */

    :root {
        --primaryBg: #0057c8;
        --primaryBgHover: #004aaa;
        --primaryText: #ffffff;
        --secondaryBg: #333333;
        --secondaryBgHover: #2b2b2b;
        --secondaryText: #ffffff;
        accent-color: var(--primaryBg);
        --headline: 'PoppinsSemiBold', sans-serif;
        --headlineBlack: 'PoppinsBold', sans-serif;
        --body: 'PoppinsRegular', sans-serif;
        --base-xs: 100%;
        --base-md: 1rem;
        
        --h1-xs: 1.802rem;
        --h2-xs: 1.602rem;
        --h3-xs: 1.424rem;
        --h4-xs: 1.266rem;
        --h5-xs: 1.125rem;
        --h6-xs: var(--base-xs);

        --h1-md: 2.488rem;
        --h2-md: 2.074rem;
        --h3-md: 1.728rem;
        --h4-md: 1.44rem;
        --h5-md: 1.2rem;
        --h6-md: var(--base-xs);

        --h1-lg: 3.998rem;
        --h2-lg: 3.157rem;
        --h3-lg: 2.369rem;
        --h4-lg: 1.777rem;
        --h5-lg: 1.333rem;
        --h6-lg: var(--base-xs);
    }

/*  ==========================================================================
    Fonts
    ==========================================================================  */

    @font-face {
        font-display: swap;
        font-family: 'PoppinsRegular';
        src: local("PoppinsRegular"), url('../../public/fonts/Poppins-Regular.ttf');
    }

    @font-face {
        font-display: swap;
        font-family: 'PoppinsSemiBold';
        src: local("PoppinsSemiBold"), url('../../public/fonts/Poppins-SemiBold.ttf');
    }

    @font-face {
        font-display: swap;
        font-family: 'PoppinsBold';
        src: local("PoppinsBold"), url('../../public/fonts/Poppins-Bold.ttf');
    }

/*  ==========================================================================
    Global
    ==========================================================================  */

    html { font-size: var(--base-xs); } /*16px*/
    body {
        font-family: var(--body);
        font-size: var(--base-xs)!important;
        font-weight: 400;
        -webkit-font-smoothing: none;
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-font-smoothing: antialiased;
        color: $default;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        font-family: var(--headline);
        font-weight: 700;
        line-height: 1.1;
        color: var(--primaryBg);
        margin: 0 0 1.05rem 0;
    }

    h1, .h1 {
        font-size: var(--h1-xs);
    }

    h2, .h2 {
        font-size: var(--h2-xs);
    }

    h3, .h3 {
        font-size: var(--h3-xs);
    }

    h4, .h4 {
        font-size: var(--h4-xs);
    }

    h5, .h5 {
        font-size: var(--h5-xs);
    }

    h6, .h6 {
        font-size: var(--h6-xs);
    }

    .condensed {
        font-size: 85%;
        line-height: 1.2;
    }

    small {

        &.xs {
            font-size: 65%;
        }
    }

    a {
        color: var(--secondaryBg);

        &:hover {
            color: var(--secondaryBgHover);
        }
    }

    @include media-breakpoint-up(md) {
        html, body {
            font-size: var(--base-md)!important;
        }

        h1, .h1 {
            font-size: var(--h1-md);
        }

        h2, .h2 {
            font-size: var(--h2-md);
        }

        h3, .h3 {
            font-size: var(--h3-md);
        }

        h4, .h4 {
            font-size: var(--h4-md);
        }

        h5, .h5 {
            font-size: var(--h5-md);
        }

        h6, .h6 {
            font-size: var(--h6-md);
        }
    }